import type { BaseConfig } from '@izimi/core';
import { appEnv, citizenApiUrl } from '@izimi/core';

class Config implements BaseConfig {
  constructor(public apiUrl: string) {}

  get environment() {
    return appEnv;
  }

  get contactEmail() {
    return 'support@izimi.be';
  }

  get supportPhone() {
    return '+3225050880';
  }

  get supportPhoneFormatted() {
    return '+32 2 505 08 80';
  }
}

const configs: Record<
  'local' | 'development' | 'test' | 'acc' | 'production',
  Config
> = {
  local: new Config(citizenApiUrl.local),
  development: new Config(citizenApiUrl.development),
  test: new Config(citizenApiUrl.test),
  acc: new Config(citizenApiUrl.acc),
  production: new Config(citizenApiUrl.production),
};

export default configs[appEnv];
